(function ($) {
  Drupal.behaviors.tabbedBlockV1 = {
    attach: function (context) {
      var $modules = $('.js-tabbed-block--v1', context);

      $modules.each(function () {
        var $module = $(this);
        var $trigger = $('.js-tabbed-block__tab', $module);
        var $pane = $('.js-tabbed-block__pane', $module);

        $trigger.off('click.tabbedBlock').on('click.tabbedBlock', function (event) {
          event.preventDefault();
          var index = $(this).index();

          switchToIndex(index);
        });

        function switchToIndex(index) {
          var $activePane = $pane.eq(index);

          // Show this tab content
          $pane.removeClass('current');
          $activePane.addClass('current');
          $trigger.removeClass('current');
          $trigger.eq(index).addClass('current');

          // Detect if we have a product grid and if so recalculate the grid
          // var $grid = $('.js-grid', $activePane);
          // if ($grid.length) {
          //   var $carousel = $('.js-product-carousel', $grid);
          //   if ($carousel) {
          //     $carousel.slick('resize');
          //   }
          //   $(document).trigger('grid.reflow');
          //   $(window).off('resize.tabbedBlock').on('resize.tabbedBlock', _.debounce(function() {
          //     $(document).trigger('grid.reflow');
          //   }, 150));
          // }
        }
      });
    }
  };
})(jQuery);
